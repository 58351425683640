<template>
  <div class="XButton">
    <button>
      <img src="../assets/images/close-24px.svg">
    </button>
  </div>
</template>

<style>
.XButton {
  position: absolute;
  left: 20px;
  top: 20px;
}
</style>
